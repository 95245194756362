import React, { useState, useEffect } from "react";
import { NavLink, Route, useHistory } from "react-router-dom";
import classes from "./index.module.scss";
import { ROUTE_PATHS } from "../../routes/RoutePaths";
import LogIn from "../../pages/LogIn/LogIn";

const Header = (props) => {
  const history = useHistory();

  const onLogout = () => {
    localStorage.removeItem("powerplay_admin_login");
    history.push("/login");
  }

  return (
    JSON.parse(localStorage.getItem("powerplay_admin_login"))?.token ?
      <nav className={classes.__header}>
        {/* <LogIn  />   */}

        {
          <ul className={classes.__navBar}>
            <li>
              <NavLink
                to={ROUTE_PATHS.home}
                exact
                activeClassName={classes.active}
              >
                Manage Games
              </NavLink>
            </li>
            <li>
              <NavLink
                to={ROUTE_PATHS.gameAnalysis}
                activeClassName={classes.active}
              >
                Game Analysis
              </NavLink>
            </li>
            <li>
              <NavLink
                to={ROUTE_PATHS.customerManager}
                activeClassName={classes.active}
              >
                Customer Manager
              </NavLink>
            </li>
            <li>
              <NavLink
                to={ROUTE_PATHS.locationManager}
                activeClassName={classes.active}
              >
                Location Manager
              </NavLink>
            </li>
            <li>
              <NavLink
                to={ROUTE_PATHS.withdrawalManager}
                activeClassName={classes.active}
              >
                Withdrawal Manager
              </NavLink>
            </li>
            <li style={{ flex: "1" }}></li>
            <li>
              {
                JSON.parse(localStorage.getItem("powerplay_admin_login"))?.token ?
                  <button style={{ padding: "8px 30px" }} onClick={() => onLogout()} activeClassName={classes.active}>
                    Logout
                  </button> : null
              }
            </li>
          </ul>
        }
      </nav> : history.push("/login")
  );
};

export default Header;
