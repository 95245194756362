export const getMinDate = (date = new Date()) => {
  let dtToday = new Date(date);

  const tomDate = new Date(date);
  tomDate.setDate(tomDate.getDate() + 1);

  console.log("TomDate: ", tomDate);

  let month = tomDate.getMonth() + 1;
  let day = tomDate.getDate();
  let year = tomDate.getFullYear();
  if (month < 10) month = "0" + month.toString();
  if (day < 10) day = "0" + day.toString();

  const maxDate = year + "-" + month + "-" + day;

  // or instead:
  // var maxDate = dtToday.toISOString().substr(0, 10);

  return maxDate;
};

export const getNHLAPIServerURL = () => {
  const server = window.location.hostname;

  if (process.env.NODE_ENV === "development" || server !=="administrator.powerplaysystems.com") {
    return "https://nhl.powerplaysystems.com";
    // return "http://localhost:4001"
  }
  return "https://prod-nhl.powerplaysystems.com";
};
export const getMLBAPIServerURL= () => {
  const server = window.location.hostname;

  if (process.env.NODE_ENV === "development" || server !=="administrator.powerplaysystems.com") {
    return "https://pce.powerplaysystems.com";
    // return "http://localhost:4001"
  }
  return "https://prod-pce.powerplaysystems.com";
};