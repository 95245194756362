import axios from "axios";

let baseURL;
baseURL = "https://api.powerplaysystems.com/";
const server = window.location.hostname;
if(server==="administrator.powerplaysystems.com"){
  baseURL = "https://prod-ppgapi.powerplaysystems.com/";
}
// baseURL = "http://localhost:4000/";
let Axios;

const init = () => {
  Axios = axios.create({
    baseURL: baseURL,
    timeout: 100000 * 1,
  });
  Axios.interceptors.request.use(handleSuccessRequest, handleErrorRequest);
  Axios.interceptors.response.use(handleSuccess, handleError);
};

const handleSuccessRequest = (request) => {
  if (localStorage.getItem("powerplay_admin_login"))
    request.headers["auth-token"] = `${
      JSON.parse(localStorage.getItem("powerplay_admin_login"))?.token
    }`;
  return request;
};

const handleErrorRequest = (error) => {
  return Promise.reject(error);
};

const handleSuccess = (response) => {
  return response;
};

const handleError = (error) => {
  return Promise.reject(error.response);
};

init();

export default Axios;