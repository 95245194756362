import React, { useEffect, useState } from "react";
import "./Login.scss";
import { useHistory } from "react-router";
import axios from "axios";
import { onAuth } from "../../services/ApiManager";

function LogIn(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();

  function historypush() {
    history.push("/");
  }

  return (
    <div className="wrapper fadeInDown">
      <div id="formContent">
        {/* <!-- Tabs Titles --> */}

        {/* <!-- Icon --> */}
        <div className="fadeIn first" className="icon">
          <img
            src="https://img.favpng.com/8/19/8/united-states-avatar-organization-information-png-favpng-J9DvUE98TmbHSUqsmAgu3FpGw.jpg"
            id="icon"
            alt="User Icon"
          />
        </div>

        {/* <!-- Login Form --> */}

        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          id="login"
          className="fadeIn second"
          name="login"
          placeholder="Email"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="fadeIn third"
          name="login"
          placeholder="password"
        />

        <button onClick={() => onAuth(email, password, historypush)}>
          Log In
        </button>
      </div>
    </div>
  );
}

export default LogIn;
