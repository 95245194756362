import React from "react";
import { ROUTE_PATHS } from "./RoutePaths";

const Home = React.lazy(() => import("../pages/Home"));
const GameAnalysis = React.lazy(() => import("../pages/GameAnalysis"));
const CustomerManager = React.lazy(() => import("../pages/CustomerManager"));
const LocationManager = React.lazy(() => import("../pages/LocationManager"));
const WithdrawalManager = React.lazy(() => import("../pages/WithdrawalManager"));

const OpenRoutes = [
  {
    path: ROUTE_PATHS.home,
    exact: true,
    name: "Home",
    component: Home,
  },
  {
    path: ROUTE_PATHS.gameAnalysis,
    exact: true,
    name: "GameAnalysis",
    component: GameAnalysis,
  },
  {
    path: ROUTE_PATHS.customerManager,
    exact: true,
    name: "CustomerManager",
    component: CustomerManager,
  },
  {
    path: ROUTE_PATHS.locationManager,
    exact: true,
    name: "LocationManager",
    component: LocationManager,
  },
	{
		path: ROUTE_PATHS.withdrawalManager,
		exact: true,
		name: "withdrawalManager",
		component: WithdrawalManager,
	},
];

export { OpenRoutes };
