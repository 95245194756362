import React, { Children, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Header from "./components/Header";
import { ROUTE_PATHS } from "./routes/RoutePaths";
import { OpenRoutes } from "./routes/Routes";
const LogIn = React.lazy(() => import("../src/pages/LogIn/LogIn"));

function App() {
  let Routes = OpenRoutes;

  return (
    <Router>
      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <div style={{ flex: 1 }}>
          <React.Suspense fallback={() => <span></span>}>
            <Switch>
              <Route
                path={ROUTE_PATHS.logIn}
                exact={true}
                name="Login"
                component={LogIn}
              />
              {Routes.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) =>
                      JSON.parse(localStorage.getItem("powerplay_admin_login"))
                        ?.token ? (
                        <>
                          <Header />
                          <route.component {...props} />
                        </>
                      ) : (
                        <Redirect to={ROUTE_PATHS.logIn} />
                      )
                    }
                  />
                );
              })}
            </Switch>
          </React.Suspense>
        </div>
      </div>
    </Router>
  );
}
export default App;
