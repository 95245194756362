import axios from "../axios-setup";
import { getMLBAPIServerURL, getNHLAPIServerURL } from "../helpers/common";

let ONLINE_STATUS = true;

const sendGetRequest = async (_url, _params) => {
  try {
    let response = await axios.get(_url, {
      data: _params ? _params : null,
    });

    ONLINE_STATUS = true;

    return response;
  } catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("powerplay_admin_login");
    }
    let err = [];
    err.error = error;
    err.no_result = true;
    if (ONLINE_STATUS !== false) {
      ONLINE_STATUS = false;
      // window.location.href = BASE_URL + "login";
      // alert("Unable to connect with server");
    }
    return err;
  }
};

const sendPostRequest = async (_url, _params) => {
  try {
    let response = await axios({
      method: "post",
      url: _url,
      data: _params ? _params : null,
    });

    ONLINE_STATUS = true;

    return response;
  } catch (error) {
    if (error.status === 401) {
      localStorage.removeItem("powerplay_admin_login");
    }
    let err = [];
    err.error = error;
    err.no_result = true;

    setTimeout(() => {
      if (ONLINE_STATUS != false) {
        ONLINE_STATUS = false;
        // alert("Unable to connect with server");
      }
    }, 400);
    return err;
  }
};

const sendPatchRequest = async (_url, _params) => {
  try {
    let response = await axios({
      method: "patch",
      url: _url,
      data: _params ? _params : null,
    });

    ONLINE_STATUS = true;

    return response;
  } catch (error) {
    if (error.status === 401) {
      localStorage.removeItem("powerplay_admin_login");
    }
    let err = [];
    err.error = error;
    err.no_result = true;

    setTimeout(() => {
      if (ONLINE_STATUS != false) {
        ONLINE_STATUS = false;
        // alert("Unable to connect with server");
      }
    }, 400);
    return err;
  }
};

const sendDeleteRequest = async (_url, _params) => {
  try {
    let response = await axios({
      method: "delete",
      url: _url,
      data: _params ? _params : null,
    });

    ONLINE_STATUS = true;

    return response;
  } catch (error) {
    if (error.status === 401) {
      localStorage.removeItem("powerplay_admin_login");
    }
    let err = [];
    err.error = error;
    err.no_result = true;

    setTimeout(() => {
      if (ONLINE_STATUS != false) {
        ONLINE_STATUS = false;
        // alert("Unable to connect with server");
      }
    }, 400);
    return err;
  }
};

export const onAuth = async (email, password, historypush) => {
  await axios
    .post("admin/auth/login", {
      email: email,
      password: password,
    })
    .then((res) => {
      localStorage.setItem("powerplay_admin_login", JSON.stringify(res.data));

      console.log("res===>", res);
      if (res) {
        console.log("come");
        historypush();
      }
    })
    .catch((err) => {
      console.log("err===>", err);
    });
};

const ApiManager = {
  getCustomers: () => {
    let url = "admin/customer";
    return sendGetRequest(url, {}, {});
  },
  createGame: (gameData) => {
    let url = "admin/power-dfs-games";
    return sendPostRequest(url, gameData, {});
  },
  getAllGames: (current_page) => {
    let url = `admin/game-list?current_page=${current_page}`;
    return sendGetRequest(url, {}, {});
  },
  editGame: (gameData) => {
    let url = "admin/edit-game";
    return sendPostRequest(url, { gameData }, {});
  },
  gameActiveToggle: (game_id, status) => {
    let url = "admin/game-active-toggle";
    return sendPostRequest(url, { game_id, status }, {});
  },
  getPrizePayouts: (gameId) => {
    let url = `admin/prize-payouts/${gameId}`;
    return sendGetRequest(url, {}, {});
  },
  getAllPowers: () => {
    let url = `admin/powers`;
    return sendGetRequest(url, {}, {});
  },
  getPowers: (gameId) => {
    let url = `admin/powers/${gameId}`;
    return sendGetRequest(url, {}, {});
  },
  getAllPointSystem: () => {
    let url = `admin/point-system`;
    return sendGetRequest(url, {}, {});
  },
  getPointSystem: (gameId) => {
    let url = `admin/point-system/${gameId}`;
    return sendGetRequest(url, {}, {});
  },
  createLocation: (locationManagerRow) => {
    let url = "admin/location-manager";
    return sendPostRequest(url, locationManagerRow, {});
  },
  getLocation: () => {
    let url = "admin/location";
    return sendGetRequest(url, {}, {});
  },
  getCountires: () => {
    let url = "admin/countries";
    return sendGetRequest(url, {}, {});
  },
  getStates: (countryId) => {
    let url = `admin/states/${countryId}`;
    return sendGetRequest(url, {}, {});
  },
  getFantacyPowers: () => {
    let url = `admin/fantacy-powers`;
    return sendGetRequest(url, {}, {});
  },
  createFantacyPower: (fantasy_power) => {
    let url = `admin/fantacy-powers`;
    return sendPostRequest(url, fantasy_power, {});
  },
  editFantacyPower: (fantasy_power, power_id) => {
    let url = `admin/fantacy-powers?power_id=${power_id}`;
    return sendPatchRequest(url, { fantasy_power, power_id }, {});
  },
  removeFantacyPower: (power_id) => {
    let url = `admin/fantacy-powers?power_id=${power_id}`;
    return sendDeleteRequest(url, power_id, {});
  },

  getPaymentMode: () => {
    let url = `admin/payment-modes`;
    return sendGetRequest(url, {}, {});
  },
  createPaymentMode: (payment_mode) => {
    let url = `admin/payment-modes`;
    return sendPostRequest(url, payment_mode, {});
  },
  giveBonusPrizeToUser: (details) => {
    let url = `admin/give-bonus-to-user`;
    return sendPostRequest(url, details, {});
  },
  editPaymentMode: (payment_mode, payment_mode_id) => {
    let url = `admin/payment-modes/${payment_mode_id}`;
    return sendPatchRequest(url, { payment_mode, payment_mode_id }, {});
  },
  removePaymentMode: (payment_mode_id) => {
    let url = `admin/payment-modes/${payment_mode_id}`;
    return sendDeleteRequest(url, payment_mode_id, {});
  },

  getGameStats: (league) => {
    let url = `admin/game-stats/${league}`;
    return sendGetRequest(url, {}, {});
  },

  getUserWithdrawal: () => {
    let url = `admin/balance-withdraw`;
    return sendGetRequest(url, {}, {});
  },
  setStatusWithdrawal: (balance_withdraw_id, payment_status) => {
    let url = `admin/balance-withdraw/${balance_withdraw_id}`;
    return sendPatchRequest(url, { payment_status }, {});
  },
  updateIpverify:(val)=>{
    let url=`admin/check-ip-verification`;
    return sendPostRequest(url, {val}, {});
  },
  
  getIpverify:(value)=>{
    let url=`admin/check-ip-verification`;
    return sendGetRequest(url, {}, {});
  },
  getSelectedGTGameList: async (currency, league, current_page) => {
    // let url = `admin/get-game-list?currency=${currency}&league=${league}&current_page=${current_page}`;
    // return sendGetRequest(url, {}, {});

    let url = `${getNHLAPIServerURL()}/api/v1/services/admin/allGameStats`;
    // let response = await axios({ method: "post", url: url });
    console.log("===>=== data ===<===", {
      current_page: current_page,
      currency: currency,
      league: league,
    });
    const response = await axios.post(url, {
      current_page: current_page,
      currency: currency,
      league: league,
    });
    return response;
  },

  getSelectedGTGameListMLB: async (currency, league, current_page) => {
    // let url = `admin/get-game-list?currency=${currency}&league=${league}&current_page=${current_page}`;
    // return sendGetRequest(url, {}, {});

    let url = `${getMLBAPIServerURL()}/mlb/api/v1/admin/all-game-stats?page=${current_page}`;
    // let response = await axios({ method: "post", url: url });
    console.log("===>=== data ===<===", {
      current_page: current_page,
      currency: currency,
      league: league,
    });
    const response = await axios.get(url, {
      current_page: current_page,
      currency: currency,
      league: league,
    });
    return response;
  },
  getAllSetGames:async(startDate,endDate)=>{
    
    let url = `${getMLBAPIServerURL()}/mlb/api/v1/games/get-matches-between-dates`;
    // let response = await axios({ method: "post", url: url });
    const response = await axios.post(url, {
      startDate:startDate,
      endDate:endDate
    });
    return response;
  },
  getAllPitchers:async()=>{
    let url = `${getMLBAPIServerURL()}/mlb/api/v1/games/get-all-pitchers`;
    const response = await axios.get(url, {      
    });
    return response;
  },
  getResultOfTheGame:async(gameID)=>{
    
    let url = `${getNHLAPIServerURL()}/api/v1/services/fantasy/getFantasyRankings`;
    // let response = await axios({ method: "post", url: url });
    const response = await axios.post(url, {
      gameID: gameID
    });
    return response;
  },
  getResultOfTheGameMLB:async(gameID)=>{
    
    let url = `${getMLBAPIServerURL()}/mlb/api/v1/games/get-fantasy-game-rankings?gameId=${gameID}`;
    // let response = await axios({ method: "post", url: url });
    const response = await axios.get(url, {});
    return response;
  },
  getUsersTeam:async(gameID,userID)=>{
    
    let url = `${getNHLAPIServerURL()}/api/v1/services/fantasy/getFantasyTeam`;
    // let response = await axios({ method: "post", url: url });
    const response = await axios.post(url, {
      gameID: gameID,
      userID:userID,
      sportID:2    
    });
    return response;
  },
  getUsersTeamMLB:async(gameID,userID)=>{
    let url = `${getMLBAPIServerURL()}/mlb/api/v1/team/get-fantasy-team?gameId=${gameID}&userId=${userID}`;
    // let response = await axios({ method: "post", url: url });
    const response = await axios.get(url, {});
    return response;
  },
  saveProbablePitchersData:async(data)=>{
    let url = `${getMLBAPIServerURL()}/mlb/api/v1/games/edit-probable-pitchers`;
    // let response = await axios({ method: "post", url: url });
    const response = await axios.post(url, {
      editPitchersList:data
    });
    return response;
  },
  getAllNHLGames:async()=>{
    let url = `${getNHLAPIServerURL()}/api/v1/services/admin/getEnrolledUserList`;
    let url1 = `${getMLBAPIServerURL()}/mlb/api/v1/admin/get-enrolled-users`;
    // let response = await axios({ method: "post", url: url });
    const response = await axios.post(url, {});
    const response1 = await axios.get(url1, {});
    response1?.data?.map((item)=>{
      item._id=item?.id
      item.enrolled_users=item?.enrolledUsers
    })
    let finalRes=[...response?.data?.data,...response1?.data]

    return finalRes;
  },
  getUserHistory:async(user_id)=>{
    let url = `${getNHLAPIServerURL()}/api/v1/services/fantasy/getUserHistory?userID=${user_id}`;
    let usl2=`${getNHLAPIServerURL()}/api/v1/services/fantasy/getUserHistoryRankings?userID=${user_id}&page=1`
    const response = await axios.get(url, {});
    const response2 = await axios.get(usl2, {});
    if(response?.data?.userGameHistory){
      response?.data?.userGameHistory?.forEach((item,index)=>{
        let ranking=response2?.data?.userRankings?.filter((data)=>data?.gameID===item?.gameID)
        if(ranking?.length>0){
          response.data.userGameHistory[index].rank=ranking[0]?.rankings[0]?.rank
          response.data.userGameHistory[index].prize=ranking[0]?.rankings[0]?.prize
        }else{
          response.data.userGameHistory[index].rank=0
          response.data.userGameHistory[index].prize=0 
        }
      })
    }
    return response?.data?.userGameHistory
  },
  getFilterGame: async (filterPayload) => {
    let url = `admin/game-list-filter?currentPage=${filterPayload.currentPage}&filterBy=${filterPayload.filterBy}&filter=${filterPayload.filter}&forenvironment=${filterPayload.forenvironment}`;
    return sendGetRequest(url, {}, {});
  },
};

export { ApiManager };
